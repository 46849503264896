import React from 'react'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'

import { Box, Typography, Button } from '@mui/material'

import tech from '../assets/tech.svg'
const FootBanners = () => {
  const { isMobile } = useScreenResolution();
  return (
    <div>
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: isMobile ? "column" : "row",
        gap: "2rem",
        padding: isMobile ? "2rem 1rem" : "4rem",
        paddingTop: "3rem",
        background: "#F5F9FF",
        marginY: "2rem",
        maxWidth: isMobile ? "90%" : "1130px",
        margin: "5rem auto",
        marginTop: "5rem",
        borderRadius: "10px",
      }}>
        <Box sx={{
          display: "flex",
          padding: isMobile ? "0" : "2rem",
          paddingLeft: isMobile ? "0" : "5rem",
          flexDirection: "column",
          gap: "1.5rem",
          justifyContent: "space-around",
        }}>
          <Box>
            <Typography variant='h4' color="rgba(14, 98, 203, 1)" sx={{ fontWeight: "bold", fontSize: isMobile ? "30px" : "40px" }}>Have questions? Give us a call at <a href="tel:+919019110452" style={{ fontWeight: "bold", fontSize: isMobile ? "30px" : "40px", color: "rgba(14, 98, 203, 1)", textDecoration: "underline" }}>9019110452</a></Typography>
            <br />
            <Typography color="#676767" style={{ fontSize: isMobile ? "18px" : "20px" }} >Get in touch with us and let us pamper your device</Typography>
          </Box>
          <Box>
            <Button variant='contained' sx={{ padding: "1rem 3rem", textTransform: "none", fontSize: "18px", background: "#892BE2" }} fullWidth={isMobile} onClick={() => { window.scrollTo(0, 0) }} >Get quotes</Button>
          </Box>
        </Box>
        {/* <Box>
          <img src={tech} alt="" width={"440px"} height={"440px"} />
        </Box> */}
      </Box>
    </div>
  )
}

export default FootBanners