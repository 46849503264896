import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import phoneCallImage from "../../assets/home/footer-phone.svg";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import RequestCallbackDialog from "./RequestCallbackDialog";
import SocialMedia from "./SocialMedia";
import { Check } from "@mui/icons-material";

const RequestCallback = () => {
  const { isDesktopOrLaptop } = useScreenResolution();
  const [openRequestCallback, setOpenRequestCallback] = React.useState(false);

  const handleClose = () => setOpenRequestCallback(false);
  function check() {
    return (
      window.location.pathname !== "/ap-enquiry-order" ||
      window.location.pathname !== "/thanks_ln_enq" ||
      window.location.pathname !== "/thanks_ln_enq_2" ||
      window.location.pathname !== "/thanks_order"
    );
  }
  const isOk = check();
  return (
    <Stack sx={{ color: "white", marginTop: "1rem" }} spacing={4}>
      <RequestCallbackDialog
        handleClose={handleClose}
        openRequestCallback={openRequestCallback}
      />
      {isDesktopOrLaptop && (
        <Typography color={"white"} textAlign={"center"}>
          We are just a call away
        </Typography>
      )}
      {isDesktopOrLaptop && (
        <Box sx={{ justifyContent: "center", display: "flex" }}>
          <img src={phoneCallImage} alt={phoneCallImage} />
          &nbsp;
          <Typography
            textAlign={"center"}
            sx={{ alignItems: "center" }}
            color={"white"}
          >
            9019110452
          </Typography>
        </Box>
      )}
      <Button
        variant="outlined"
        size="large"
        sx={{
          color: "white",
          borderColor: "white",
          paddingLeft: "3vw",
          paddingRight: "3vw",
          display: isOk ? "none" : "",
        }}
        onClick={() => setOpenRequestCallback(true)}
      >
        Request a Callback
      </Button>
      {isDesktopOrLaptop && <SocialMedia />}
    </Stack>
  );
};

export default RequestCallback;
