import React, { useState, useEffect } from 'react'
// import Carousel from 'react-bootstrap/Carousel';
import { useDispatch } from "react-redux";
import { updateAuthFields } from '../../../store/slices/AuthSlice';
// import { useHistory, useNavigate, useNavigation } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, Box, Typography } from '@material-ui/core'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Carousel from "react-material-ui-carousel";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useScreenResolution } from '../../../hooks/ResponsiveHook';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useMediaQuery, useTheme } from '@mui/material';
// import img3 from '../assets/offerbanner1.jpg'
// import img2 from '../assets/offerbanner2.jpg'
// import img1 from '../assets/offerbanner3.jpg'
import img1 from '../assets/landingSlider.webp'
import iso from '../assets/iso.svg'
import twmonth from '../assets/twmonth.svg'
import google from '../assets/google.svg'
import star from '../assets/star.svg'
import '../css/Hero.css'
import Model_Form from './Model_Form';
import Issue_Form from './Issue_Form';
import Brand_Form from './Brand_Form';
import { Button } from '@mui/material';
import OTP from './OTP';
import { Fullscreen } from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CTAButton from './CTAButton';
import { validateMobileNumberNew } from '../../../utils';
// const items = [img1,img2,img3];
const items = [img1];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Hero = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigation();
  const url = window.location.href;

  const fburl = 'fbclid';
  const gurl = 'gclid';
  const HOST = process.env.REACT_APP_API_URL;
  const { isMobile, isTabletOrMobile } = useScreenResolution();
  const [Step, setStep] = useState(1);
  //Selections
  const [DeviceList, setDeviceList] = useState([]);
  const [Name, setName] = useState('');
  const [Mobile, setMobile] = useState();
  const [source, setsource] = React.useState('');
  const [subsource, setsubsource] = React.useState('');
  const [devicedetails, setdevicedetails] = useState({
    device_name: '',
    device_id: '',
    brand_id: ''
  });
  const [modeldetails, setmodeldetails] = useState({
    model_name: '',
    model_id: '',
  });
  const [issuedetails, setissuedetails] = useState({
    issue_name: '',
    issue_id: ''
  })

  const [namemobile, setnamemobile] = useState({
    cust_name: '',
    cust_mobile: '',
  });

  const [islogin, setislogin] = useState({
    otpby: '',

  });
  const [ShowOtp, setShowOtp] = useState(false);

  const fetchDeviceList = async () => {
    fetch(HOST + '/api/v1/cms/exclusive_apple_services', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    }).then((resp) => resp.json())
      .then((respJson) => {
        setDeviceList(respJson.data);
      }).catch((error) => {
        console.log(error);
      })
  }


  // const validate = () => {
  //   console.log("Mobile Lenght",Mobile.length);    
  //   if(Mobile && Mobile.length <= 10 && Name){
  //     return true;
  //   }
  //   return false;

  // };

  const setDevice_android = (itemName, itemCategoryId, itemBrandId) => {
    setOpen(false);
    const deviceInfo = {
      device_name: itemName,
      device_id: itemCategoryId,
      brand_id: itemBrandId
    };
    setdevicedetails(deviceInfo);
    // fetchModelList();
    setStep(4);

  }
  const set_Laptop = (itemName, itemCategoryId, itemBrandId) => {
    setOpen(false);
    const deviceInfo = {
      device_name: itemName,
      device_id: itemCategoryId,
      brand_id: itemBrandId
    };
    setdevicedetails(deviceInfo);
    setStep(4);
  }
  const setDevice = async (itemName, itemCategoryId, itemBrandId) => {
    setOpen(false);
    const deviceInfo = {
      device_name: itemName,
      device_id: itemCategoryId,
      brand_id: itemBrandId
    };
    setdevicedetails(deviceInfo);
    // fetchModelList();
    setStep(Step + 1);
  }
  const [NoName, setNoName] = useState(false);
  const [NoMobile, setNoMobile] = useState(false);
  const [NoDevice, setNoDevice] = useState(false);

  const remove_error = () => {
    setNoName(false);
    setNoMobile(false);
  }

  const ValidateForm = () => {
    if (isMobile) {
      window.scrollTo(0, 600);
    }
    const MobileString = Mobile;
    if (!Name || !Mobile || (MobileString && MobileString.length != 10) || !devicedetails.device_id || !issuedetails.issue_name) {

      if (!Name) {
        setNoName(true);
      }
      if (!Mobile || (MobileString && MobileString.length != 10)) {
        setNoMobile(true);
      }
      else {
        // setNoDevice(true);
        // window.alert("Please select Device and Issue");
        setOpenAlert(true);
      }
    }
    else {
      setShowOtp(true);
      fetch(HOST + '/api/v1/users/send_otp_landing', {
        method: 'POST',
        body: JSON.stringify({
          mobile: Mobile,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json())
        .then((resposeJson) => {
          if (resposeJson.success == false) {
            console.log("Response Error");
          }
          else {
            const nextStep = {
              otbby: resposeJson.purpose,
            };
            console.log(nextStep);
            setislogin(nextStep);
          }
        }).catch((error) => {
          console.log(error);
        });
      const SetNameMobile = {
        cust_name: Name,
        cust_mobile: Mobile,
      };
      setnamemobile(SetNameMobile);
    }
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [load, setload] = useState(true);
  setTimeout(() => {
    setload(false);
  }, 2000);
  const [openAlert, setOpenAlert] = useState(false);
  const closeAlert = () => {
    setOpenAlert(false);
  }
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={closeAlert}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  const [DeviceName, setDeviceName] = useState('');
  const handleChange = (event) => {
    setDeviceName(event.target.value);
  }

  useEffect(() => {
    fetchDeviceList();
  }, []);
  return (
    <div>
      <Snackbar
        open={openAlert}
        autoHideDuration={3000}
        onClose={closeAlert}
        message="Issue and Device Not added"
        action={action}
      />
      <Box display={"grid"} gridTemplateColumns={isMobile ? "100%" : "58% 38%"} sx={{
        padding: isMobile ? "1rem" : "2rem",
        gap: isMobile ? "18px" : "2rem",
        marginY: isMobile ? "0rem" : "2rem",
      }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: isMobile ? "18px" : "1.2rem" }}>
          <Box spacing="1">
            <h1 className='Heroheader'>Experience the Premium Device Care Right at Your Doorstep</h1>
            <Typography style={{ color: "rgba(103, 103, 103, 1)", fontSize: isMobile ? "16px" : "22px", fontWeight: "400", lineHeight: isMobile ? "24px" : "32px" }} >Book a service with our experts and get your smartphone serviced at your home. We take care of all device issues like screen damage, battery, speaker, charging, and a lot more.</Typography>
          </Box>
          <Box sx={{ width: "100%", margin: "auto", height: isTabletOrMobile ? "180px" : "300px", position: "relative" }} >
            <Skeleton variant="rectangular" sx={{ zIndex: items && load ? '-1' : '500', position: "absolute", height: isTabletOrMobile ? "160px" : "250px", top: "0", left: "0", right: "0", bottom: "0", backgroundColor: "transparent" }} />
            <Carousel
              NextIcon={<ArrowForwardIosIcon />}
              PrevIcon={<ArrowBackIosNewIcon />}
              indicators={false}
              fullWidth
              sx={{ zIndex: !items && load ? '-1' : '500', position: "absolute", height: isTabletOrMobile ? "180px" : "300px", top: "0", left: "0", right: "0", bottom: "0" }}
            >
              {items ? (
                items.map((item, index) => (
                  <Box className='carousal-div' key={index} sx={{ width: "100%", backgroundColor: "transparent" }} >
                    <img
                      src={item}
                      alt=""
                      style={{ width: "100%", height: isTabletOrMobile ? "180px" : "300px" }}
                    />
                  </Box>
                ))
              ) : <></>}
            </Carousel>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.2rem", justifyContent: "space-between" }}>
          <Box sx={{ height: "90%", padding: isMobile ? "0.5rem" : "1rem 1rem", gap: "0.8rem", display: "flex", flexDirection: "column", justifyContent: isMobile || ShowOtp ? "flex-start" : "space-around", boxShadow: isMobile ? "none" : "8px 10px 30px 10px rgba(0, 0, 0, 0.08)", borderRadius: "10px" }}>
            {!isMobile && <div>
              <Typography style={{ fontSize: "24px", fontWeight: "400", textAlign: "center" }} >Your Queries, Our Expertise</Typography>
              <Typography style={{ fontSize: "20x", fontWeight: "400", color: " #676767", textAlign: "center" }} >How Can We Assist You Today?</Typography>
            </div>}
            <Box sx={{ display: "flex", flexDirection: "column", height: ShowOtp ? "85%" : "80%", gap: ShowOtp || isMobile ? "1rem" : "1.6rem" }} >
              {!isMobile && <FormControl fullWidth sx={{ display: Step === 1 ? "" : "none" }}>
                <InputLabel id="demo-simple-select-label" >Select Device </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  fullWidth
                  value={DeviceName}
                  onChange={handleChange}
                >
                  {DeviceList.map((item, index) => (
                    <MenuItem key={index} value={item.itemName} onClick={() => setDevice(item.itemName, item.itemCategoryId, item.itemBrandId)} >{item.itemName}</MenuItem>
                  ))}
                  <MenuItem value={"Android Mobile"} onClick={() => setDevice_android('mobile', '1', '')} >Android Mobile</MenuItem>
                  <MenuItem value={"Laptop"} onClick={() => setDevice_android('laptop', '3', '')} >Laptops</MenuItem>
                </Select>
              </FormControl>}
              {isMobile && <Button variant='outlined' sx={{ border: "1px solid #ababab", padding: "15px 14px", color: "#5d5d5d", textTransform: "capitalize", display: "flex", justifyContent: "space-between", fontSize: "16px", display: Step === 1 ? "" : "none" }} endIcon={<KeyboardArrowDownIcon />} onClick={handleClickOpen} >Select Device </Button>}

              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={fullScreen}
                maxWidth="md"
                onClose={handleClose}
                sx={{
                  marginTop: "80%",
                  Padding: "0"
                }}

                PaperProps={{
                  sx: {
                    borderRadius: { lg: "8px" },
                    borderTopLeftRadius: isMobile ? "1.5rem" : "",
                    borderTopRightRadius: isMobile ? "1.5rem" : ""
                  }
                }}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle >
                  <Typography style={{ fontSize: "1.6rem", fontWeight: "bold" }}> Select Device</Typography>

                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "0.8rem" }}>
                      {DeviceList.map((item, index) => (
                        <Box key={index} sx={{ cursor: "pointer", borderBottom: "3px solid #F5F6Fa", paddingY: 0.8 }} onClick={() => setDevice(item.itemName, item.itemCategoryId, item.itemBrandId)} >
                          <Typography style={{ fontSize: "1.5rem" }} >{item.itemName}</Typography>
                        </Box>
                      ))}
                      <Box key={5} sx={{ cursor: "pointer", borderBottom: "3px solid #F5F6Fa", paddingY: 0.8 }} onClick={() => setDevice_android('mobile', '1', '')}>
                        <Typography style={{ fontSize: "1.5rem" }} >Android Mobile</Typography>
                      </Box>
                    </Box>
                  </DialogContentText>
                </DialogContent>
              </Dialog>



              {Step === 2 && <Model_Form devicedetails={devicedetails} Step={Step} setStep={setStep} setmodeldetails={setmodeldetails} setdevicedetails={setdevicedetails} setDeviceName={setDeviceName} />}
              {Step === 3 && <Issue_Form devicedetails={devicedetails} modeldetails={modeldetails} Step={Step} setStep={setStep} setissuedetails={setissuedetails} />}
              {Step === 4 && <Brand_Form devicedetails={devicedetails} setdevicedetails={setdevicedetails} setStep={setStep} Step={Step} />}
              <TextField error={NoName} id="outlined-basic" label={NoName ? "Enter Name" : "Name"} variant="outlined" fullWidth onChange={(e) => { setName(e.target.value); setNoName(false) }} />
              <TextField error={Mobile && (Mobile.length != 10)} id="outlined-basic" type='number' label={NoMobile ? "Incorrect Number" : "Phone Number"} variant="outlined" fullWidth onChange={(e) => { setMobile(e.target.value); setNoMobile(false); }} />

              {/* <TextField error={Mobile.length > 10} type='number' inputMode='numberic' fullWidth onChange={(e)=>{setMobile(e.target.value);setNoMobile(false);}}/> */}
              {ShowOtp && <OTP namemobile={namemobile} devicedetails={devicedetails} modeldetails={modeldetails} issuedetails={issuedetails} islogin={islogin} />}
              {!ShowOtp && <div onClick={() => ValidateForm()}><CTAButton /></div>}
              {/* { !ShowOtp && <Button variant='contained' onClick={()=>ValidateForm()} sx={{height:"55px", fontSize:"18px", textTransform:"none",background:"#0E62CB"}} >Check price for free</Button>} */}
            </Box>
          </Box>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            placeItems: "center"
          }}>
            <Box width={"70%"}>
              <img src={iso} alt="isoCertified" width={"100%"} />
            </Box>
            <Box width={"70%"} sx={{ display: "flex", justifyContent: "center" }}>
              <img src={twmonth} alt="isoCertified" width={"70%"} />
            </Box>
            <Box sx={{
              width: "70%",
              paddingTop: "5px",
              display: "flex",
              justifyContent: "center"
            }}>
              <img src={google} alt="" width={"90%"} />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Hero