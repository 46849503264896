import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/newLogoBold.png'
import callimg from '../../../assets/call.png'
import whatsapp from '../../../assets/whatsapp.png'
import '../css/TopBar.css'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'

const TopBar = () => {
  const { isMobile } = useScreenResolution();
  return (
    <div>
      <div className="TopBarWrapper">
        <div className="contain" style={{ padding: isMobile ? "1rem" : "2rem" }}>
          <a href='/' className="logosection" >
            <img src={logo} alt="" srcset="" width={isMobile ? "80px" : "80px"} />
          </a>
          <div className="ctaSocial">
            <a href="tel:+919019110452">
              <img src={callimg} alt="" />
              <p style={{ display: isMobile ? "none" : "" }} ><Link to="tel:+919019110452" >9019110452</Link></p>
            </a>
            <a href="https://api.whatsapp.com/send?phone=+919019110452&amp;text=Hi Hello RE, I would like to book a service for my Device" >
              <img src={whatsapp} alt="" srcset="" />
              <p style={{ display: isMobile ? "none" : "" }} ><Link to="https://api.whatsapp.com/send?phone=+919019110452&amp;text=Hi Hello RE, I would like to book a service for my Device">Whatsapp Us</Link></p>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar