import { Box, Typography } from '@mui/material'
import React from 'react'
import logo from '../assets/WhiteBGLogo.png'
import paymentPartner from '../../../assets/home/footer-payment-cards.svg'
import { Button } from 'bootstrap'
import RequestCallback from '../../../atoms/footer/RequestCallback'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'
import { FOOTER_CATEGORIES } from "../../../constants"

const FooterMobile = () => {
  const { isMobile } = useScreenResolution();

  return (
    <div>
      <Box sx={{
        background: "#0E62CB",
        display: "flex",
        padding: "2rem",
        justifyContent: "space-between",
        alignItems: isMobile ? "center" : "left",
        gap: "2rem",
        flexDirection: isMobile ? "column" : "row",
      }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "0.8rem",
          width: "100%"
        }}>
          <img src={logo} alt="" srcset="" width={"160px"} />
          <Typography align='center' sx={{ color: "white", width: "100%" }} >Premium Doorstep Service</Typography>
        </Box>
        <Box paddingTop={2}>
          <Typography variant='h5' sx={{ color: "white" }} >Company</Typography>
          <br />
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem"
          }}>
            { }
            <a href='https://hellore.in/about' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> About us</a>
            <a href='https://hellore.in/contact' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Contact us</a>
            <a href='https://hellore.in/blog' target='blank' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Blog</a>
            {/* <a href='/termsofUse' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Terms Of Use</a> */}
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1.2rem"
        }} >
          <RequestCallback />
          <Typography align='center' sx={{ color: "white", width: "100%" }} >2020-2024 &copy; All Rights Reserved </Typography>
          <Typography align='center' sx={{ color: "white", width: "100%" }} >Secure Payment</Typography>
          <img src={paymentPartner} ></img>
        </Box>
      </Box>
    </div>
  )
}

const Footer = () => {
  const { isMobile } = useScreenResolution();
  console.log(FOOTER_CATEGORIES.COMPANY.items);
  return isMobile ? <FooterMobile /> : (
    <div>
      <Box sx={{
        background: "#0E62CB",

      }}>
        <Box sx={{
          maxWidth: "1210px",
          display: "flex",
          padding: "2rem",
          justifyContent: "space-between",
          alignItems: isMobile ? "center" : "left",
          gap: "2rem",
          flexDirection: isMobile ? "column" : "row",
          margin: "0 auto"
        }}>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "0.8rem",
          }}>
            <img src={logo} alt="" srcset="" width={"142px"} />
            <Typography sx={{ color: "white" }} >Premium Doorstep Service</Typography>
            <Typography sx={{ color: "white" }} >2020-2024 &copy; All Rights Reserved </Typography>
            <Typography sx={{ color: "white" }} >Secure Payment</Typography>
            <img src={paymentPartner} ></img>
          </Box>
          <Box paddingTop={2}>
            <Typography variant='h5' sx={{ color: "white" }} >Company</Typography>
            <br />
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.2rem"
            }}>
              <a href='https://hellore.in/about' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> About us</a>
              <a href='https://hellore.in/contact' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Contact us</a>
              <a href='https://hellore.in/blog' target='blank' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Blog</a>
              <a href='https://hellore.in/terms-and-conditions' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Terms and conditions</a>
              <a href='https://hellore.in/warranty-policy' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Warranty Policy</a>
              <a href='https://hellore.in/privacy-policy' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Privacy Policy</a>
              {/* <a href='/termsofUse' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Terms Of Use</a> */}
              {/* <a href='/cancellation' style={{ color: "white", cursor: "pointer", textDecoration: "none" }}> Cancellation Policy</a> */}
            </Box>
          </Box>
          <Box>
            <RequestCallback />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Footer