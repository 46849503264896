import React, { useState, useEffect } from "react";
// import '../app.css';
import "./css/Home.css";
import { Typography } from "@mui/material";
// import Navbar from "../../component/Navbar";
import Why from "./component/Why";
import How from "./component/How";
import Head from "./component/Head";
import Hero from "./component/hero";
import Navbarlanding from "./component/Navbarlanding";
import Compare from "./component/Compare";
import Counter from "./component/Counter";
import Testimonial from "./component/Testimonial";
// import Footer from "./component/Footer";
// import Footer from "../home/Footer";
// import Compare from "../../component/Compare"
import WhyERIP from "../home/WhyERIP";
import HowItWorks from "../home/HowItWorks";
import TestimonialsSection from "../home/TestimonialsSection";
import Acord from "./component/Acord";
import Footer from "./component/Footer";
import Weaccept from "./component/Weaccept";
import Fab from "@mui/material/Fab";
import Slide from "@mui/material/Slide";
// import Pop from "./component/Pop";
import "bootstrap/dist/css/bootstrap.min.css";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import Paper from "@mui/material/Paper";
import { Box } from "@material-ui/core";
import whatspp from "../../assets/whatsapp.png";
import call from "../../assets/call.png";
import { useScreenResolution } from "../../hooks/ResponsiveHook";
import HowWeCompare from "./component/HowWeCompare";
import { shadows } from "@mui/system";
import Grow from "@mui/material/Grow";
// import Otp from "../../component/Otp";

function App() {
  // alert(process.env.REACT_APP_API_URL);
  // const [how,sethow] = useState(false);
  const [Value, setValue] = useState(0);
  const [showResults, setShowResults] = React.useState(0);
  const { isMobile } = useScreenResolution();
  const go_up = () => {
    window.scrollTo(0, 0);
  };
  const [Scrollbar, setScrollbar] = useState(false);
  const changeNav = () => {
    if (window.scrollY >= 350 && window.scrollY <= 3000) {
      setScrollbar(true);
    } else {
      setScrollbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);
  const mystyle = {
    // width:"100%",
    padding: "1.5rem 2rem",
    backgroundColor: "#EB870E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    height: "50px",
    // display:  isMobile && Scrollbar ?"":"none"
  };
  return (
    <>
      <Navbarlanding handler={setShowResults} />
      {/* <Head handler={setShowResults} showResults={showResults}  /> */}
      <Slide direction="up" in={isMobile && Scrollbar}>
        {/* <Fab variant="extended" style={mystyle} onClick={()=>{go_up();setShowResults(1)}}> */}
        <Paper
          sx={{
            position: "fixed",
            bottom: 1,
            left: 0,
            right: 0,
            zIndex: 800,
            padding: "5px 0",
          }}
          elevation={2}
        >
          <BottomNavigation
            showLabels
            value={Value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "80px",
              }}
            >
              <a href="tel:9019110452">
                <img className="im2" src={call} alt="" />
              </a>
              <small>Call Us</small>
            </div>
            <Box
              style={mystyle}
              onClick={() => {
                go_up();
                setShowResults(1);
              }}
            >
              <Typography fontWeight="bold" color="white">
                Get Free Quote
              </Typography>
            </Box>
            {/* <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} /> */}
            <div
              className=""
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "80px",
              }}
            >
              <a href="https://api.whatsapp.com/send?phone=918047184455&amp;text=Hi Hello RE, I would like to book a service for my Device">
                <img className="im2" src={whatspp} alt="" />
              </a>
              <small>Chat Now</small>
            </div>
          </BottomNavigation>
        </Paper>
        {/* <Typography fontWeight='bold' color='white'>Get Free Quote</Typography> */}
      </Slide>
      <Hero handler={setShowResults} showResults={showResults} />
      <Weaccept />
      {/* <Otp/> */}
      <WhyERIP />
      {/* <Compare  handler={setShowResults}  /> */}
      <HowWeCompare handler={setShowResults} />
      <HowItWorks />

      <div
        className="col-sm-12 text-center am1"
        style={{ backgroundColor: "#FCEFEF", padding: "20px 15px" }}
      >
        <h1 className="counterheader" style={{ marginBottom: "30px" }}>
          Bangalore’s Favourite Trusted Service Partner
        </h1>
        <div className="col am" style={{ padding: "0" }}>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-4 col-4">
              <Counter count="12200" heading="Happy Customers" />
            </div>
            <div className="col-md-4 col-4">
              <Counter count="15800" heading="Services Fulfilled" />
            </div>
            <div className="col-md-4 col-4">
              <Counter count="60" heading="Expert Technicians" />
            </div>
          </div>
        </div>
      </div>
      <Testimonial></Testimonial>
      <Acord />
      <br />
      <br />
      <Footer />
    </>
  );
}

export default App;
