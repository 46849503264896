import React, { useState, useEffect } from 'react'
import thank from '../assets/thank.png'
import { Typography, Box, Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'
import { BYPASS_LOGIN_SSR } from '../../../services/ApiConstants'
const ThankYouHeader = () => {
    const { isMobile } = useScreenResolution();
    // const convenience_fee_inc_tax = useSelector(({ orders }) => orders.convenience_fee_inc_tax);
    const convenience_fee_inc_tax = localStorage.getItem("convenience_fee_inc_tax");
    const discountedAmount = useSelector(({ cart }) => cart.discountedAmount);
    const [enqdetails, setwnqdetails] = useState({
        model: "",
        issue_name: "",
        issue_display_price: "",
        issue_discounted_price: "",
    });
    const enquiryId = localStorage.getItem("enquiry_id_encrypted");
    // alert(enquiryId);
    const fetch_enquery_details = () => {
        const nextFormState = {
            ...enqdetails,
            model: localStorage.getItem("model"),
            issue_name: localStorage.getItem("issue_name"),
            issue_display_price: localStorage.getItem("issue_display_price"),
            issue_discounted_price: localStorage.getItem("issue_discounted_price"),

            // mobile_error:'',
        };
        setwnqdetails(nextFormState);
    };
    const HOST = process.env.REACT_APP_API_URL;

    const [issuelist, setissuelist] = useState([]);
    const [total_amount, settotal_amount] = useState([]);

    const fetch_order_details = async() => {
        let token = localStorage.getItem("token");

        let bearer = "Bearer " + token;

        await fetch(HOST + "/api/v1/users/orders?order=" + enquiryId, {
            method: "GET",

            headers: {
                Authorization: bearer,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((responseJson) => {

                setissuelist(responseJson.data[0].order_issues);
                settotal_amount(responseJson.data[0].order_payment_total);
                if (responseJson.success == false) {
                    // setissuelist([]);

                } else {

                }

                // setLoading(false);
                //  console.log(responseJson.data)
            })
            .catch((error) => {
                //Error
                console.error(error);
            });

    }


    useEffect(() => {
        fetch_enquery_details();
        fetch_order_details();
    }, []);

    const [couponcoeddetails, setcouponcoeddetails] = useState({
        couponcode: localStorage.getItem("couponcode"),
        couponamount: localStorage.getItem("amount"),
    });

    const openMyBookings = () => {
        // navigate("/bookings")

        // BY Pass Login
        const token = window.localStorage.getItem("token");
        const phoneNumber = window.localStorage.getItem("cus_mobile");
        document.cookie = `token=${token}`
        document.cookie = `phoneNumber=${phoneNumber}`
        window.location.href = "https://erip.in/orders"

        // Normal 
        // window.location.href = '/orders';
    };
    return (
        <div>
            <Box sx={{
                display: "grid",
                gridTemplateColumns: isMobile ? "100%" : "100%",
                gap: "1.5rem",
                marginTop: isMobile ? "1rem" : "2rem",
                padding: "1rem"
            }}>
                {!isMobile && <Box>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"1rem"}>
                        <img src={thank} alt="" />
                        <Typography align='center' sx={{ fontSize: "24px", fontWeight: "bold" }}>Thank You! Your Booking is Confirmed</Typography>
                    </Box>
                    <Typography sx={{
                        fontSize: "20px",
                        textAlign: "center"
                    }}>
                        {/* {`Congratulations! We have confirmed your booking${couponcoeddetails.couponcode !== null?`, and you also
                  recieved an additional discount of ₹ ${couponcoeddetails.couponamount}!`:``}`} */}
                    </Typography>
                </Box>}
                {isMobile && <Box>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"1rem"}>
                        <img src={thank} alt="" width={"80px"} />
                        <Typography align='center' sx={{ fontSize: "20px", fontWeight: "bold" }}>Thank You! Your Booking is Confirmed</Typography>
                    </Box>
                    <Typography sx={{
                        fontSize: "20px",
                        textAlign: "center"
                    }}>
                        {/* {`Congratulations! We have confirmed your booking${couponcoeddetails.couponcode !== null?`, and you also
                  recieved an additional discount of ₹ ${couponcoeddetails.couponamount}!`:``}`} */}
                    </Typography>
                </Box>}
                <div style={{ width: "100%", borderTop: "0.1px solid rgb(179 179 179)", }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem"
                }}>
                    {!isMobile && <Typography sx={{ fontSize: isMobile ? "24px" : "32px", fontWeight: "bold" }} >Order Details</Typography>}
                    <Typography sx={{ fontSize: "20px", fontWeight: "600" }}> Model - {enqdetails.model}</Typography>
                    {issuelist.map((item, index) => (
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            gap: "1rem"
                        }}>
                            <Typography sx={{ fontSize: isMobile ? "18px" : "24px", fontWeight: isMobile ? "bold" : "" }} >
                                {item.issue_name}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: isMobile ? "0.8rem" : "2rem" }} >
                                <Typography sx={{ color: "#858585", textDecoration: "line-through", fontSize: isMobile ? "17px" : "22px" }} >
                                    ₹ {item.issue_display_price}
                                </Typography>
                                <Typography sx={{ fontSize: isMobile ? "17px" : "22px" }}>
                                    ₹ {item.issue_discounted_price}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1.5rem",
                    }}>
                        <Typography sx={{ fontSize: "22px" }}>Tax & Fees</Typography>
                        <Typography sx={{ fontSize: "22px" }}>₹{" "}{convenience_fee_inc_tax}</Typography>
                    </Box>
                    {(couponcoeddetails.couponcode == null) ? <></> : <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "1.5rem",
                    }}>
                        <Typography sx={{ fontSize: "22px" }}>Discounted ({couponcoeddetails.couponcode})</Typography>
                        <Typography sx={{ fontSize: "22px" }}>₹{" "}{couponcoeddetails.couponamount}</Typography>
                    </Box>}
                    <Box sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }} >
                        <Box>
                            <Typography sx={{ fontSize: isMobile ? "20px" : "24px" }}>Total Amount</Typography>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: isMobile ? "1rem" : "2rem"
                        }}>
                            <Typography sx={{ fontSize: "24px", fontWeight: "bold", padding: isMobile ? "2px 5px" : "5px 8px" }} >
                                ₹ {total_amount - couponcoeddetails.couponamount}
                            </Typography>
                        </Box>
                    </Box>
                    {/* <Typography align='center' fontSize={"18px"} sx={{ marginTop: "1.5rem" }}>
                        To check the status of your booking, please click Track Order Status
                    </Typography> */}
                    <Button variant='contained' fullWidth sx={{ padding: "10px 16px", fontSize: "20px", background: "#0ac7ab",fontWeight:"bold", textTransform:"capitalize" }} onClick={openMyBookings} >Track Booking Status</Button>
                </Box>

            </Box>
            <br />
            {/* <div style={{ width: "100%", borderTop: "0.1px solid rgb(179 179 179)", }} /> */}
        </div>
    )
}

export default ThankYouHeader