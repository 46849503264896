import React from 'react'

import { Container, Box, Typography } from '@material-ui/core'
import hl1 from '../assets/Frame 627186.png'
import hl2 from '../assets/Group 9098.png'
import isocertified from '../assets/isocertified.png';
import startupind from '../assets/strtupind.png'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'

const HighlightsMobile = () => {
  return (
    <div>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} padding={"1.5rem"} sx={{ gap: "1.5rem", margin: "2rem 0" }}>
        <Typography variant='h3' align='center' style={{ fontSize: "30px", fontWeight: "bold" }} >Highlights <span style={{color:"#892BE2"}}>@Hello RE</span></Typography>
        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} sx={{ gap: "1rem" }} >
          <Box sx={{
            display:"grid",
            gridTemplateColumns:"48% 48%",
            gap:"1rem"
          }}>
            <Box >
              <img src={hl1} alt="" />
            </Box>
            <Box sx={{borderRadius: "10px" }}>
              <img src={hl2} alt="" />
            </Box>
          <Box>
            <img src={isocertified} alt="" />
          </Box>
          <Box>
            <img src={startupind} alt="" />
          </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
const Highlights = ({mode=""}) => {
  const { isMobile, isTabletOrMobile } = useScreenResolution();
  return isTabletOrMobile ? <HighlightsMobile /> : (
    <div>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} padding={"2rem"} sx={{ gap: "1.5rem", margin: "2rem auto", maxWidth:"1500px" }}>
        <Typography  align='center' style={{ fontSize: "30px", fontWeight: "bold" }} >Highlights <span style={{color:"#892BE2"}}>@Hello RE</span></Typography>
        <Box sx={{display:"grid", gridTemplateColumns:"1fr 1fr 1fr 1fr", gap:mode === "home"?"40px":"15px", width:"100%",placeItems:"center"}} >
          <Box sx={{ height: "319px", width:"100%",  borderRadius: "10px", }}>
            <img src={hl2} alt="" style={{height:"100%", width:"100%"}} />
          </Box>
          <Box sx={{ height: "319px", width:"100%"  }}>
            <img src={startupind} alt="" style={{height:"100%", width:"100%"}} />
          </Box>
          <Box sx={{ height: "319px", width:"100%" }}>
            <img src={hl1} alt="" style={{height:"100%", width:"100%"}} />
          </Box>
          <Box sx={{ height: "319px", width:"100%" }}>
            <img src={isocertified} alt="" style={{height:"100%", width:"100%"}} />
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Highlights