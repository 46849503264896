import React from 'react'
import { useScreenResolution } from '../../../hooks/ResponsiveHook'

import { Box,Typography } from '@mui/material'
import star from '../assets/star.svg'
const Partner = () => {
    const {isMobile} = useScreenResolution();
  return (
    <div>
        <Box sx={{
            display:"flex",
            flexDirection: "column",
            gap:"3rem",
            alignItems:"center",
            background:"#FEF4E2",
            padding: {lg:" 2rem",md:"2rem",xs:"2rem 1rem"},
            maxWidth:isMobile?"90%":"1130px",
            margin: "2rem auto",
            marginTop:"5rem",
            borderRadius:"10px"
        }} >
            <Box>
                <Typography variant='h4' align='center' sx={{color:"rgba(14, 98, 203, 1)", fontWeight:"bold",fontSize:isMobile?"30px":"40px"}} >Banglore's <span style={{color:"#F6AE2D"}} >Favourite</span> Trusted Service Partner</Typography>
            </Box>
            <Box sx={{
                display:"flex",
                flexDirection: isMobile?"column":"row",
                justifyContent:"space-between",
                gap:isMobile?"2rem":"4rem",
            }}>
                <Box>
                    <Typography variant='h3' align='center' color={"rgba(14, 98, 203, 1)"} sx={{fontWeight:"bold", display:"flex",alignItems:"center", justifyContent:"center"}} > <img src={star} alt="" /> 4.7</Typography>
                    <Typography color={"rgba(14, 98, 203, 1)"} align='center' sx={{fontSize:"22px"}} >Star Ratings on Google</Typography>
                </Box>
                {/* <div style={{border:"0.05px solid rgba(14, 98, 203, 1)", height:isMobile?"":"80px"  }}/> */}
                <hr style={{border:"1px solid #F6AE2D"}} />
                <Box>
                    <Typography variant='h3' align='center' color={"rgba(14, 98, 203, 1)"} sx={{fontWeight:"bold"}} >30K+</Typography>
                    <Typography color={"rgba(14, 98, 203, 1)"} align='center' sx={{fontSize:"22px"}} >Happy customers served</Typography>
                </Box>
                {/* <div style={{border:"0.05px solid rgba(14, 98, 203, 1)", height:isMobile?"":"80px"  }}/> */}
                <hr style={{border:"1px solid #F6AE2D"}} />
                <Box>
                    <Typography variant='h3' align='center' color={"rgba(14, 98, 203, 1)"} sx={{fontWeight:"bold"}} >50K+</Typography>
                    <Typography color={"rgba(14, 98, 203, 1)"} align='center' sx={{fontSize:"22px"}} >Services fulfilled</Typography>
                </Box>
            </Box>
        </Box>
    </div>
  )
}

export default Partner